<template>
  <div class="map-container">
    <div class="filters" style="display: flex; flex-direction: row; align-items: end; margin: 5px; gap: 15px; flex-wrap: wrap; justify-content: space-between">
      <div style="display: flex; align-items: end; gap: 18px; flex-wrap: wrap;">      
        <div class="form-group" style="margin-bottom: 0;">
          <label for="fechainicio">Fecha inicio:</label>
          <input style="width: 150px;" id="fechainicio" v-model="fechaInicio" type="date" class="form-control">
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="fechafin">Fecha fin:</label>
          <input style="width: 150px;" id="fechafin" v-model="fechaFin" type="date" class="form-control">
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="operario">Operario:</label>
          <select style="width: 230px;" v-model="operarioseleccionado" id="operario" class="form-control">
            <option value="-1">Seleccione operario</option>
            <option value="">Todos</option>
            <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
          </select>
        </div>
        <div  class="form-group" style="margin-bottom: 0;">
          <label for="especialidades">Especialidades:</label>
          <select style="width: 200px;" v-model="especialidadseleccionada" id="especialidades" class="form-control">
            <option value="-1">Seleccione especialidad</option>
            <option value="">Todas</option>
            <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
          </select>
        </div>
        <div>
          <button @click="cargarDatos()" class="btn btn-light" style="border: 1px solid grey;">Filtrar</button>
        </div>
      </div>
      <div style="margin-left: 22px;">
        <div class="row">
          <span>Nº Visitas Totales </span>
          <strong>&nbsp;{{ totalPuntos }}</strong>
        </div>
        <div class="row" style="align-items: end;">
          <img style="width: 30px;" src="@/assets/img/casa.png" title="Ubicación del servicio">
          <span style="font-size: x-large;"></span>
          <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Visitas por confirmar">
          <span style="font-size: x-large;">{{ visitas_confirmar.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png" title="Visitas pendientes">
          <span style="font-size: x-large;">{{ visitas_pendientes.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" title="Visitas en domicilio">
          <span style="font-size: x-large;">{{ visitas_domicilio.length }}</span>
          <img src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Visitas finalizadas">
          <span style="font-size: x-large;">{{ visitas_finalizadas.length }}</span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-left: 22px; gap: 10px; flex-wrap: wrap;">
        <span>Desde</span>
        <input v-model="direccionEscrita" style="width: 200px;" class="form-control" type="text">
        <span>al domicilio del asegurado</span>
        <div>
          <button @click="generarRuta()" class="btn btn-primary">Generar ruta</button>
        </div>
      </div>
    </div>
    <div class="map-and-instructions">
      <div class="map" ref="myMapRef"></div>
      <div v-if="routeInfo" class="instructions bg-white rounded-lg shadow-md">
        <div class="p-4 rounded-t-lg" style="background-color: aliceblue;">
          <h2 class="text-xl font-semibold mb-2">Resumen de la ruta</h2>
          <p class="text-sm mt-2">
            <span class="font-semibold">Distancia:</span> {{ routeInfo.totalDistance }} • 
            <span class="font-semibold">Tiempo:</span> {{ routeInfo.totalTime }}
          </p>
        </div>
        <ul class="divide-y divide-gray-200">
          <li v-for="(instruction, index) in routeInfo.instructions" :key="index" 
              :class="['p-3 flex items-start', index % 2 === 0 ? 'bg-gray-50' : 'bg-white']">
            <span class="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-500 flex items-center justify-center mr-3 mt-1">
              {{ index + 1 }}
            </span>
            <div class="instruction-content">
              <div class="flex items-center mb-1">
                <span class="ml-2 text-sm font-medium" v-html="instruction.instruction"></span>
              </div>
              <span class="text-xs text-gray-500">{{ instruction.distance }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { Loader } from '@googlemaps/js-api-loader'; // Importa Google Maps API Loader
  import { PwgsApi } from '../../../../services/PwgsApi';
  import { createApp } from 'vue';
  import MapPopup from './MapPopup.vue';
  
  export default {
    props:['id'],
    data() {
      return {
        operarioseleccionado: -1,
        especialidadseleccionada: -1,
        center: { lat: 39.4746227, lng: -0.3477796 },
        origin: { lat: 39.46975, lng: -0.37739 }, // Puntos de origen
        destination: { lat: 39.98975, lng: -0.04679 }, // Puntos de destino
        map: null,
        directionsService: null,
        directionsRenderer: null,
        instructions: [], // Aquí se almacenarán las instrucciones paso a paso
        apiKey: '', // Clave de la API de Google Maps
        visitas_confirmar: [], // Almacenar las visitas confirmadas
        visitas_pendientes: [], // Almacenar las visitas pendientes
        visitas_domicilio: [],
        visitas_finalizadas: [],
        gps_operarios_app: [],
        gps_operarios_vehiculo: [],
        coordenadas: [],
        totalPuntos:0,
        operarios: [],
        especialidades: [],
        fechaFin:'',
        fechaInicio:'',
        markers: [],
        puntos:[],
        direccionEscrita:'',
        google:'',
        tiempo:'',
        routeInfo: null,
      };
    },
    methods: {   
      fechaActualMasOnce() {
        const hoy = new Date();
        this.fechaInicio = this.fechaing(hoy.toLocaleDateString());
        const fechaFinTemp = new Date(hoy);
    
        // Sumamos 11 días
        fechaFinTemp.setDate(fechaFinTemp.getDate() + 11);

        // Asignamos la fecha de fin
        this.fechaFin = this.fechaing(fechaFinTemp.toLocaleDateString());
      },
      fechaing(fecha) {
        const [dia, mes, año] = fecha.split("/");
        return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      },
      irServicio(servicio) {
        this.$router.push({
                name: 'Servicio',
                params: {
                    id:servicio.idservicios
                }
            });
      },
      fechaesp(fecha) {
        const [año, mes, dia] = fecha.split("-");
        return `${dia}/${mes}/${año}`;
      },  
      
      async cargarDatos() {
        const api = new PwgsApi();
        let subidadatos = { tipo: 'mapa' , id_servicio: this.id};
        const response = await api.post('google-maps-key', subidadatos);
        this.apiKey = response;

        if(this.fechaInicio == ''){
          this.fechaActualMasOnce();
        }

        var body = {};
        this.puntos = [];
        body.fecha_inicio = this.fechaesp(this.fechaInicio);
        body.fecha_fin = this.fechaesp(this.fechaFin);
        console.log('idserv',this.$props.id);
        body.id_servicio = this.$props.id;
        if(this.operarioseleccionado !='-1'){
          body.id_operario = this.operarioseleccionado;
        }
        if(this.especialidadseleccionada !='-1'){
          body.id_especialidad = this.especialidadseleccionada;
        }
  
        // Cargar las visitas
        this.puntos = await api.post('planning-pwgs/geografico', body);
        this.coordenadas = this.puntos.coordenadas;
        this.visitas_confirmar = this.puntos.visitas_confirmar || [];
        this.visitas_pendientes = this.puntos.visitas_pendientes || [];
        this.visitas_domicilio = this.puntos.visitas_domicilio || [];
        this.visitas_finalizadas = this.puntos.visitas_finalizadas || [];
        this.gps_operarios_app = this.puntos.gps_operarios_app || [];
        this.gps_operarios_vehiculo = this.puntos.gps_operarios_vehiculo || [];
        this.totalPuntos = this.visitas_domicilio.length+ this.visitas_finalizadas.length + this.visitas_pendientes.length + this.visitas_confirmar.length;
        console.log('coords', this.coordenadas);
  
        // Cargar Google Maps API y configurar el mapa
        const loader = new Loader({
          apiKey: this.apiKey,
          version: 'weekly',
        });
  
        // Espera a que la API de Google Maps se cargue antes de inicializar el mapa
        loader.load().then((google) => {
          this.initMap(google);
        }).catch((err) => {
          console.error('Error al cargar Google Maps', err);
        });
      },
  
      initMap(google) {
        var center = {lat: parseFloat(this.coordenadas.split(',')[0]), lng: parseFloat(this.coordenadas.split(',')[1])};
        console.log('latlng', center);
        // Inicializa el mapa usando el objeto 'google' que fue pasado al resolver la promesa
        this.map = new google.maps.Map(this.$refs.myMapRef, {          
          center: center,
          zoom: 7,
        });

        // Coloca los marcadores para las visitas confirmadas y pendientes
        this.marcarVisitas(google);
        
        // Inicializa DirectionsService y DirectionsRenderer
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: this.map,
        });
        
      },
      
      
      calcularRutaDesdeServicio(visita){
        console.log('visitaAcalcular',visita);
        var [lat, lng] = visita.coordenadas_servicio.split(',').map(Number);
        const origen = { lat, lng };
        [lat, lng] = this.coordenadas.split(',').map(Number); // Destino
        const destination = { lat, lng };
        this.calcularRuta(origen, destination);
      },
      async itinerario(visita){
        
        const api = new PwgsApi();
        let subidadatos = { tipo: 'indicaciones' , id_servicio: this.id};
        await api.post('google-maps-key', subidadatos);
        var coordenadas = await api.get('planning-pwgs/'+this.id+'/coordenadas-visitas-dia/'+visita.idvisitador);
        console.log('coordss', coordenadas);
        const waypoints = coordenadas.slice(1, -1).map(coord => {
          const [lat, lng] = coord.split(',');
          return {
            location: new this.google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
            stopover: true
          };
        });

        const [startLat, startLng] = coordenadas[0].split(',');
        const [endLat, endLng] = coordenadas[coordenadas.length - 1].split(',');

        const request = {
          origin: new this.google.maps.LatLng(parseFloat(startLat), parseFloat(startLng)),
          destination: new this.google.maps.LatLng(parseFloat(endLat), parseFloat(endLng)),
          waypoints: waypoints,
          travelMode: this.google.maps.TravelMode.DRIVING, // También puedes usar WALKING, BICYCLING, etc.
        };

        this.directionsService.route(request, (result, status) => {
          if (status === 'OK') {
            this.directionsRenderer.setDirections(result);
            this.procesarInstrucciones(result);
          } else {
            console.error('Error al generar la ruta:', status);
          }
        });
      },
      procesarInstrucciones(result) {
        const route = result.routes[0].legs;
        const totalDistance = route.reduce((acc, leg) => acc + leg.distance.value, 0) / 1000; // Convertir a kilómetros
        const totalTime = route.reduce((acc, leg) => acc + leg.duration.value, 0) / 60; // Convertir a minutos

        const instructions = route.flatMap(leg =>
          leg.steps.map(step => ({
            instruction: step.instructions,
            distance: step.distance.text,
          }))
        );

        this.routeInfo = {
          totalDistance: `${totalDistance.toFixed(2)} km`,
          totalTime: `${Math.round(totalTime)} min`,
          instructions: instructions,
        };
      },
      
      marcarVisitas(google) {
        this.routeInfo = null;
        this.google = google;

        this.markers.forEach(marker => marker.setMap(null));
        this.markers = []; // Vaciar el array de marcadores

        const iconSize = new google.maps.Size(40, 40); // Tamaño del icono
        const iconScaledSize = new google.maps.Size(40, 40); // Tamaño escalado del icono

        // Iconos personalizados para los marcadores
        const iconoRojo = {
            url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // Icono rojo
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoAmarillo = {
            url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png', // Icono amarillo
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoAzul = {
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Icono azul
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoVerde = {
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', // Icono verde
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoPersona = {
            url: require('@/assets/img/persona.png'), // Icono personalizado para operarios (tamaño grande)
            scaledSize: iconSize // Ajusta el tamaño del icono
        };
        const iconoCoche = {
            url: require('@/assets/img/coche.png'), // Icono personalizado para vehículos (tamaño grande)
            scaledSize: iconSize // Ajusta el tamaño del icono
        };
        const createPolylineFromGPSData = (gpsData, map) => {
          const routeCoordinates = gpsData.map((punto) => ({
            lat: parseFloat(punto.latitud_gps),
            lng: parseFloat(punto.longitud_gps),
          }));

          // Crea la polilínea con el color morado
          const routePath = new google.maps.Polyline({
            path: routeCoordinates,
            geodesic: true,
            strokeColor: '#800080', // Color morado
            strokeOpacity: 1.0,
            strokeWeight: 4,
          });

          // Dibuja la polilínea en el mapa
          routePath.setMap(map);
          console.log('acabaPuntos', this.map)
        };
        console.log('puntosAcs', this.puntos);
        try{
          if (this.puntos.gps_dia_app_operario.length>0) {
            console.log('puntosEntra');
            const gpsData = this.puntos.gps_dia_app_operario;
            createPolylineFromGPSData(gpsData, this.map);
          }
        }catch(e){
          console.log();
        }

        const createMarkerWithPopup = (position, map, icon, popupContent, infoContent) => {
          const marker = new google.maps.Marker({
            position,
            map,
            icon,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: popupContent,
          });

          const infoDialog = new google.maps.InfoWindow({
            content: infoContent,
          });

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });
          marker.addListener('mouseover', () => {
            infoDialog.open(map, marker);
          });
          marker.addListener('mouseout', () => {
            infoDialog.close();
          });

          return marker;
        };

        // Agregar marcadores para las visitas con popups personalizados
        const addVisitMarkerWithPopup = (visita, icon, map) => {
          const [lat, lng] = visita.coordenadas_servicio.split(',').map(Number);
          
          const popupContent = document.createElement('div');
          const popupApp = createApp(MapPopup, {
            visita,
            onCalcularTiempo: (visitaData) => {
              this.calcularTiempoLlegada(visitaData);
            },
            onCalcularRuta: (visitaData) => {
              this.calcularRutaDesdeServicio(visitaData);
            },
            onIrServicio: (visitaData) => {
              this.irServicio(visitaData);
            },
            onItinerario: (visitaData) => {
              this.itinerario(visitaData);
            }
          });
          
          popupApp.mount(popupContent);

          let infoContent = '';
          if(visita.operario_nombre){
            infoContent = `<p>${visita.operario_nombre}</p>`;
          }else{
            infoContent = `<p>${visita.nombre}</p>`;
          }
          createMarkerWithPopup({ lat, lng }, map, icon, popupContent, infoContent);
        };        

        // Agregar marcadores para coordenadas (icono morado)
        const [lat, lng] = this.coordenadas.split(',').map(Number);
        const markerServicio = new google.maps.Marker({
          position: { lat, lng },
          map: this.map,
          icon: {url:require("@/assets/img/casa.png"), scaledSize: iconSize},
          title: 'Ubicación servicio',
        });
        this.markers.push(markerServicio);

        // Visitas confirmadas (icono rojo)
        if (this.visitas_confirmar) {
          this.visitas_confirmar.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoRojo, this.map);
          });
        }

        // Visitas pendientes (icono amarillo)
        if (this.visitas_pendientes) {
          this.visitas_pendientes.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoAmarillo, this.map);
          });
        }

        // Visitas finalizadas (icono azul)
        if (this.visitas_finalizadas) {
          this.visitas_finalizadas.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoAzul, this.map);
          });
        }

        // Visitas a domicilio (icono verde)
        if (this.visitas_domicilio) {
          this.visitas_domicilio.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoVerde, this.map);
          });
        }

        // Marcadores para operarios en aplicación y vehículos (personas y coches)
        if (this.gps_operarios_app) {
          this.gps_operarios_app.forEach(visita => {
            const lat = parseFloat(visita.latitud_gps);
            const lng = parseFloat(visita.longitud_gps);
            let popupContent = `          
              <div style="width:300px; margin:8px">            
                <div class="row" style="align-items:center">
                  <i class="fas fa-user-cog"></i>
                  <p style="margin:0">&nbsp;${visita.operario_nombre}</p>`;

            // Condicionales en JavaScript en lugar de v-if
            if (visita.operario_telefono1 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono1}</p>`;
            }

            if (visita.operario_telefono2 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono2}</p>`;
            }
            let infoContent = `<p>Ubicación del operario</p>`;
            createMarkerWithPopup({ lat, lng }, this.map, iconoPersona, popupContent, infoContent);
          });
        }

        if (this.gps_operarios_vehiculo) {
          this.gps_operarios_vehiculo.forEach(visita => {
            const lat = parseFloat(visita.latitud_gps);
            const lng = parseFloat(visita.longitud_gps);
            let popupContent = `          
              <div style="width:300px; margin:8px">            
                <div class="row" style="align-items:center">
                  <i class="fas fa-user-cog"></i>
                  <p style="margin:0">&nbsp;${visita.operario_nombre}</p>`;

            // Condicionales en JavaScript en lugar de v-if
            if (visita.operario_telefono1 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono1}</p>`;
            }

            if (visita.operario_telefono2 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono2}</p>`;
            }     
            let infoContent = `<p>Ubicación del vehículo del operario</p>`;
            createMarkerWithPopup({ lat, lng }, this.map, iconoCoche, popupContent, infoContent);
          });
        }
      },       
      

      async generarRuta() {
        if (!this.direccionEscrita) {
          alert("Por favor, ingresa una dirección válida.");
          return;
        }

        // Crear un objeto Geocoder para convertir la dirección en coordenadas
        const geocoder = new this.google.maps.Geocoder();

        // Usar Geocoder para obtener las coordenadas de la dirección escrita
        geocoder.geocode({ address: this.direccionEscrita }, (results, status) => {
          if (status === "OK") {
            const origin = results[0].geometry.location; // Coordenadas de la dirección escrita
            const [lat, lng] = this.coordenadas.split(',').map(Number); // Destino
            const destination = { lat, lng };

            // Llamar a la función para calcular la ruta
            this.calcularRuta(origin, destination);
          } else {
            alert("No se pudo encontrar la dirección: " + status);
          }
        });
      },

      async calcularTiempoLlegada(visita) {
        const api = new PwgsApi();
        let subidadatos = { tipo: 'duracion' , id_servicio: this.id};
        await api.post('google-maps-key', subidadatos);

        if (!this.directionsService) {
          console.error('El servicio de direcciones no está inicializado');
          return;
        }

        const [originLat, originLng] = visita.coordenadas_servicio.split(',').map(Number);
        const [destLat, destLng] = this.coordenadas.split(',').map(Number);

        const origin = new this.google.maps.LatLng(originLat, originLng);
        const destination = new this.google.maps.LatLng(destLat, destLng);

        const request = {
          origin: origin,
          destination: destination,
          travelMode: this.google.maps.TravelMode.DRIVING
        };

        try {
          const response = await new Promise((resolve, reject) => {
            this.directionsService.route(request, (result, status) => {
              if (status === this.google.maps.DirectionsStatus.OK) {
                resolve(result);
              } else {
                reject(status);
              }
            });
          });

          const route = response.routes[0];
          const leg = route.legs[0];
          const duration = leg.duration.text;

          // Mostrar el resultado en un alert (puedes modificar esto para mostrar el resultado de otra manera)
          alert(`Tiempo estimado de llegada: ${duration}`);

        } catch (error) {
          console.error('Error al calcular el tiempo de llegada:', error);
          alert('No se pudo calcular el tiempo de llegada. Por favor, inténtelo de nuevo.');
        }
      },

      async calcularRuta(origin, destination) {
        const request = {
          origin: origin,
          destination: destination,
          travelMode: 'DRIVING',
        };

        this.directionsService.route(request, (result, status) => {
          if (status === 'OK') {            
            this.directionsRenderer.setDirections(result);
            this.extraerInstrucciones(result);
          } else {
            console.error('Error al calcular la ruta:', status);
          }
        });
      },

      async extraerInstrucciones(result, origin, destination) {
        const api = new PwgsApi();
        let subidadatos = { tipo: 'indicaciones' , id_servicio: this.id};
        await api.post('google-maps-key', subidadatos);

        const route = result.routes[0];
        const leg = route.legs[0];
        
        this.routeInfo = {
          origin: origin,
          destination: destination,
          totalDistance: leg.distance.text,
          totalTime: leg.duration.text,
          instructions: leg.steps.map(step => ({
            maneuver: step.maneuver || '',
            instruction: step.instructions,
            distance: step.distance.text,
          })),
        };
      },

      async obtenerOperarios() {
        const api = new PwgsApi();
        const response = await api.get('operarios/simple?sortField=nombre&sortOrder=1');
        this.operarios = response.datos;
      },

      async obtenerEspecialidades() {
        const api = new PwgsApi();
        const response = await api.get('especialidades');
        this.especialidades = response.datos;
      },
    },
  
    mounted() {
      this.cargarDatos(); // Cargar los datos y el mapa al montar el componente
      this.obtenerEspecialidades();
      this.obtenerOperarios();
    },
    watch:{
      id(){
        console.log('idserv2', this.id);
      }
    }
  };
  </script>
<style scoped>
.map-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.filters {
}

.map-and-instructions {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

.map {
  flex-grow: 1;
  height: 100%;
}

.instructions {
  width: 30%;
  overflow-y: auto;
  max-height: 100%;
}

.instruction-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.instruction-content span:last-child {
  align-self: flex-end;
}

@media (max-width: 768px) {
  .map-container{
    height: auto;
  }
  .map-and-instructions {
    flex-direction: column;
  }

  .map, .instructions {
    width: 100%;
    height: 400px;
  }

  .instructions {
    max-height: 50%;
  }
}
</style>