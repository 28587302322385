<template>
    <div class="card card-widget widget-user-2" v-if="perito">
        <!-- Add the bg color to the header using any of the bg-* classes -->
        <div class="widget-user-header bg-warning"
            style="border-bottom-left-radius:.25rem;border-bottom-right-radius:.25rem">
            <div class="widget-user-image">
                <i class="fas fa-user-tie" style="font-size:xxx-large;position:absolute;color:white"></i>

            </div>
            <!-- /.widget-user-image -->
            <h3 class="widget-user-username" :title="perito.nombre"
                style="font-size:1.25rem;font-weight:bold;color:white;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient: vertical;overflow: hidden;">
                {{ perito.nombre }}
            </h3>
            <h3 class="widget-user-desc" style="color:white;font-size:1.25rem">Perito</h3>
            <div class="row">
                <div class="col-md">
                    <!--<button v-if="perito.telefono1 && perito.telefono1.length > 1"
                        :title="perito.telefono1" type="button" @click="llamar(perito.telefono1)"
                        style="background-color:#007bff;border-color:#007bff" class="btn btn-block btn-info btn-xs"><i
                            class="fas fa-phone-alt"></i></button>-->
                    <a href="#" style="font-size:small" @click="llamar(perito.telefono1)" v-if="perito.telefono1 && perito.telefono1.length>1"><i
                            class="fas fa-phone-alt"></i>&nbsp;{{perito.telefono1}}</a>
                </div>
                <div class="col-md">
                    <!-- <button v-if="perito.movil1 && perito.movil1.length > 1" :title="perito.movil1"
                        type="button" @click="llamar(perito.movil1)"
                        style="background-color:#007bff;border-color:#007bff" class="btn btn-block btn-info btn-xs"><i
                            class="fas fa-phone-alt"></i></button>-->
                    <a href="#" style="font-size:small" v-if="perito.movil1 && perito.movil1.length > 1"
                        @click="llamar(perito.movil1)"><i class="fas fa-phone-alt"></i>{{ perito.movil1 }}</a>
                </div>
                <div class="col-md"><button v-if="perito.email" :title="perito.email" type="button"
                        @click="enviaremail(perito.email)" style="background-color:#007bff;border-color:#007bff"
                        class="btn btn-block btn-info btn-xs"><i class="fas fa-at"></i></button></div>
            </div>
        </div>


    </div>
</template>
<script>

export default ({
    props: ['id', 'perito'],

    data() {
        return {
          
        };
    },
    methods: {
        llamar: function (telf) {
            window.open("tel:" + telf);
        },
        enviaremail(correo) {
            location.href = "mailto:" + correo;
        }
    },
    components: {
        
    },
    mounted() {


    },
})
</script>