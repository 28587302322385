<template>
    <div class="info-box">
        <span title="Asegurado" class="info-box-icon bg-danger elevation-1 pers"><i class="fas fa-house-user"></i></span>
        <div class="info-box-content">
            <span class="info-box-text" style="font-size:small">{{ asegurado.nombre }}</span>
            <span class="info-box-text telefonoa" style="font-size:small"><i v-if="asegurado.telefono1" class="fas fa-phone-alt" style="color:black;cursor:default"></i><span v-on:click='llamar(asegurado.telefono1)'>&nbsp;&nbsp;{{ asegurado.telefono1 }} &nbsp;</span><span v-if="asegurado.telefono2" v-on:click='llamar(asegurado.telefono2)'>{{ asegurado.telefono2}}</span><span v-if="asegurado.telefono3" v-on:click='llamar(asegurado.telefono3)'>{{ asegurado.telefono3 }}</span></span>
        </div>
    </div>
</template>
<script>  

export default ({
        props: ['id', 'asegurado'],

    setup() {     
    },
    methods: {
       llamar : function(telefono){
            window.open("tel:" + telefono);
       }
    }
})


</script>
<style>
.telf{
    color: #fff !important;
    border-color: #fff !important;
}
.pers{
    background:#6f42c1 !important
}
.telefonoa {
    color:blue;
    cursor:pointer;
}
</style>